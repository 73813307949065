
.odt-single-bar {
    background: #F5F5F5 0 0 no-repeat padding-box;
    border-radius: 50px;
    opacity: 1;
    height: 50px;
    margin-left: 12px;
    margin-right: 12px;
}

.odt-single-mini-bar {
    border-radius: 50px;
    opacity: 1;
    margin-left: 12px;
    margin-right: 12px;
}


/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {

}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {

}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
    .odt-single-bar {
        height: 30px !important;
    }
    .odt-font-22 {
        font-size: 19px;
    }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .odt-single-bar {
        height: 30px !important;
    }
    .odt-font-22 {
        font-size: 17px;
    }
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
    .odt-single-bar {
        height: 30px !important;
    }
    .odt-font-22 {
        font-size: 17px;
    }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .odt-single-bar {
        height: 30px !important;
    }

    .odt-font-22 {
        font-size: 17px;
    }
}
